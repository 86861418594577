import { DEFAULT_ICON_CLASS, isIconClassAvalible } from './iconClass';

export const apiUrls = {
    calendarConfigLoadBulk: (contentType, objectIdsGetParam) => `/calendar/api/config/?content_type=${contentType}${objectIdsGetParam}`,
    sideblockInfo: '/blog/sideblock_info/',
    calendarBulkUrl: '/calendar/meeting/bulk/',
    attachmentListUrl: (contentType, objectId) => `/attachments/?content_type_id=${contentType}&object_id=${objectId}`,
    deleteAttachmentUrl: attachmentId => `/attachments/delete/${attachmentId}/`,
    editAttachmentUrl: attachmentId => `/attachments/edit/${attachmentId}/`,
    iconUrl: iconName => `/static/img/filetypes/${isIconClassAvalible(iconName) ? iconName : DEFAULT_ICON_CLASS}.svg`,
    blogUrl: slug => `/blog/${slug}`,
    requestBlogPermission: '/blog/notify_blog_permission_request/',
    taggedTimeline: tagName => `/timeline/tagged/${tagName}/`,
    fetchRestrictedBlogData: blogId => `/blog/restricted_blog/${blogId}/data/`,
    fetchRequestedSubscription: blogId => `/blog/${blogId}/requested_subscription/`,
    approveBlogSubscription: (blogId, userId) => `/blog/${blogId}/subscription/accept/${userId}/`,
    declineBlogSubscription: (blogId, userId) => `/blog/${blogId}/subscription/decline/${userId}/`,
    filterSearch: term => `/mailing/filters/search/?term=${term}`,
    entryFilterSearch: (blogId, term) => `/blog/${blogId}/entry_search/?term=${term}`,
    subscribeUsersUrl: '/blog/bulk_subscribe/',
};

export const photoDelete = (albumId, photoId) => (`/albums/delete_photo/${albumId}/${photoId}/`);

export const loadPhotoUrl = '/albums/load_photos/';
export const loadAlbumsUrl = (contentType, objectId) => `/publication/load_albums/${contentType}/${objectId}/`;
export const getBlogSubscribeUrl = blogId => `/blog/${blogId}/subscribe/`;
export const getBlogUnsubscribeUrl = blogId => `/blog/${blogId}/unsubscribe/`;
export const excludeBlogFromTimelineUrl = blogId => `/blog/${blogId}/exclude_from_timeline/`;
export const includeBlogInTimelineUrl = blogId => `/blog/${blogId}/include_in_timeline/`;
export const uniqueUserViews = objectId => `/blog/entry/${objectId}/unique_user_views/`;
export const blogUrl = blogSlug => `/blog/${blogSlug}/`;
export const entryEditUrl = slug => `/blog/entry/${slug}/edit/`;
export const entryArchiveUrl = entryId => `/blog/entry/${entryId}/archive/`;
export const entryPinUrl = (entryId, blogId) => `/blog/entry/${entryId}/${blogId}/pin/`;
export const entryUnPinUrl = (entryId, blogId) => `/blog/entry/${entryId}/${blogId}/unpin/`;
export const entryDeleteUrl = entryId => `/blog/entry/${entryId}/delete/`;
export const entryNotifyUrl = entryId => `/blog/entry/${entryId}/notify/`;
export const entryPublishUrl = entryId => `/blog/entry/${entryId}/publish/`;
export const getAddAttachmentUrl = (contentTypeId, objectId) => `/attachments/add/${contentTypeId}/${objectId}/`;
export const editBanner = (contentType, objectId, itemTitle, itemUrl) => `/home/banners/${contentType}/${objectId}/redirect/banner/?title=${itemTitle}&link=${itemUrl}`;
export const attachAlbum = (contentType, objectId) => `/albums/cloud/attach/${contentType}/${objectId}/`;
export const buildQuiz = (contentType, objectId) => `/quiz/builder/?content_type=${contentType}&object_id=${objectId}`;

export const blogEntryUrl = blogSlug => `/blog/entry/${blogSlug}/`;
export const blogAlbumsUrl = (blogSlug, albumId) => `${blogEntryUrl(blogSlug)}albums/${albumId}/`;
export const blogPhotoUrl = (blogSlug, albumId, photoId) => `${blogAlbumsUrl(blogSlug, albumId)}${photoId}/`;

export const initCreateBlogApiUrl = '/blog/api/blog/init_create_form/';
export const createBlogApiUrl = '/blog/api/blog/create/';
export const createBlogUrl = '/blog/create/';
export const editBlogUrl = blogId => `/blog/${blogId}/edit/`;
export const editBlogApiUrl = blogId => `/blog/api/blog/${blogId}/edit/`;

export const searchFilterUrl = blogId => `/search/?blog=${blogId}`;

export const sendView = '/stats/send_view/';

export const blogsUrl = '/blog-categories/';
export const albumsUrl = id => `/albums/${id}`;
